
import { defineComponent, onBeforeMount, reactive, toRefs, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ElMessage } from 'element-plus'
import { operationManualApi } from '@/api/modules/operationManual'

export default defineComponent({
  name: 'operationManualItemForm',
  props: {
    // 0:'新增', 1:'编辑', 2:'查看'
    type: {
      type: Number,
      default: null
    }
  },
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    // 导入mitt
    const mitter: any = inject('mitter')

    // 获取refs
    const addOrEditFormRef = ref()
    const wangeditorRef = ref()

    onBeforeMount(async () => {
      const { projectId, parentId } = route.query
      // console.log('projectId', projectId)
      data.projectId = Number(projectId)
      parentId && (data.addOrEditForm.parent_id = Number(parentId))

      data.getMenuList()

      props.type !== 0 && (await data.getInfo())
      data.formDisabled = props.type === 2

      data.addOrEditFormShow = true
    })

    const data: any = reactive({
      projectId: null,
      pageTitle: ['新增', '编辑', '查看'],
      // 菜单内容
      menuList: [],
      addOrEditFormShow: false,
      addOrEditForm: {
        type: 2,
        name: '',
        project_id: '',
        parent_id: '',
        iteration_id: '',
        content: ''
      },
      addOrEditRules: {
        name: [{ required: true, message: '请输入手册名称', trigger: 'blur' }],
        iteration_id: [{ required: true, message: '请选择迭代版本', trigger: 'change' }],
        parent_id: [{ required: true, message: '请选择所属菜单', trigger: 'change' }]
      },
      formDisabled: false,
      determineBtnLoading: false,

      // 富文本编辑器加载完毕
      ditorInit () {
        // console.log('富文本编辑器加载完毕')
        props.type === 2 && wangeditorRef.value.Wangeditor_onDisable()
        // 富文本回填
        props.type !== 0 && wangeditorRef.value.Wangeditor_setValue(data.addOrEditForm.content)
      },

      // 获取详情
      async getInfo () {
        const id = Number(route.query.id)
        const { res } = await operationManualApi.getInfo({ id })
        console.log('获取详情', res)
        data.addOrEditForm = res

        // 数据处理
        data.addOrEditForm.terationSelectedObj = {
          id: res.iteration_id,
          name: res.iteration_name
        }
      },

      // 获取菜单
      async getMenuList () {
        const { res } = await operationManualApi.getList({ project_id: data.projectId })
        // console.log('获取菜单', res)
        data.menuList = res
      },

      // 返回
      onReturn () {
        router.go(-1)
      },

      // 提交
      onSubmit () {
        console.log('data.addOrEditForm', data.addOrEditForm)
        addOrEditFormRef.value.validate(async (valid: any) => {
          if (!valid) return

          // 提取富文本
          const ditorValue = wangeditorRef.value.Wangeditor_getValue()
          // console.log('提取富文本', ditorValue)
          if (!ditorValue) return ElMessage.error('请输入内容！')
          data.addOrEditForm.content = ditorValue
          data.addOrEditForm.project_id = data.projectId

          try {
            data.determineBtnLoading = true
            await operationManualApi.save(data.addOrEditForm)
            ElMessage.success(`${data.pageTitle[props.type]}成功`)
            router.go(-1)
            data.determineBtnLoading = false

            // mitt推送让列表更新
            mitter.emit('helpCenterOperationManualUpdateList')

            // 关闭标签导航栏
            store.commit('TagsView/DEL_Name_Tags', props.type === 0 ? 'operationManualAdd' : 'operationManualEdit')
          } catch (error) {
            data.determineBtnLoading = false
          }
        })
      }
    })

    return {
      ...toRefs(data),
      addOrEditFormRef,
      wangeditorRef
    }
  }
})
